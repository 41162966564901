import React from "react"
import { Link } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import Seamless from "components/Seamless"
import { Section, Container } from "../components/Layouts"

function MarketingAnalysis() {
  return (
    <Layout language="en">
      <SEO title="Marketing Analysis" robots="noindex" noGtag />

      <Section>
        <Container>
          <Link className="back-link mb-2" to="/" style={{ display: "block" }}>
            BACK
          </Link>
          <Seamless src="/marketingAnalysisForm.js" />
        </Container>
      </Section>
    </Layout>
  )
}

export default MarketingAnalysis
